'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var emitter = require('emitter');

var dom = require('form-widget-dom');
var settings = require('form-widget-settings');
var option = require('form-widget-option');
var outdatedMsg = require('./outdated-msg');
var selectmenu = require('form-widget-selectmenu');

var $shippingAddressWrapper;
var $shippingAddressOptionsWrapper;
var $shippingMethodWrapper;
var $billingAddressCountry;
var $billingAddressState;
var $billingAddressCounty;
var $shippingAddressCountry;
var $shippingAddressState;
var $shippingAddressCounty;
var $paymentDetailsWrapper;
var $paymentMethodWrapper;
var hasPaymentField;

var hasShipping = function() {

	return !!$shippingAddressWrapper.length;

};

var isShippingAndBillingAddressSame = function() {

	return !hasShipping() || hasShipping() && $shippingAddressOptionsWrapper.hasClass('fb-payment-shipping-address-options-same');

};

var getSelectedOptionId = function(id) {

	return parseInt(id.split('-').pop(), 10);

};

var getSelectedShippingMethod = function() {

	return $shippingMethodWrapper.find(':checked');

};

var getShippingId = function() {

	return getSelectedShippingMethod().attr('data-shipping-id');

};

var getSelectedLocation = function() {

	var data = {};

	if (isShippingAndBillingAddressSame()) {

		data.countryCode = $billingAddressCountry.val();
		data.stateCode = $billingAddressState.val();
		data.county = doesCountyOptionExist($billingAddressCounty) ? $billingAddressCounty.val() : '';

	} else {

		data.countryCode = $shippingAddressCountry.val();
		data.stateCode = $shippingAddressState.val();
		data.county = doesCountyOptionExist($shippingAddressCounty) ? $shippingAddressCounty.val() : '';

	}

	return data;

};

var getShippingMethods = function() {

	var locationData = getSelectedLocation();
	var countryCode = locationData.countryCode;

	return $.ajax({
		method: 'POST',
		cache: false,
		dataType: 'json',
		url: settings.shippingMethodsUrl,
		data: {
			data: JSON.stringify({
				timeStamp: settings.formTimeStamp,
				selectedOption: getSelectedOptionId(getSelectedShippingMethod()[0].id) + 1,
				countryCode: countryCode,
				extraFees: option.getExtraFees()
			})
		}
	}).done(function(response) {

		if (response.status) {

			$shippingMethodWrapper.html(response.html);

		} else {

			outdatedMsg.show(response.msg);

		}

	});

};

var getPaymentDetails = function(opts) {

	var locationData = getSelectedLocation();
	var data = {
		timeStamp: settings.formTimeStamp,
		extraFees: option.getExtraFees(),
		countryCode: locationData.countryCode,
		stateCode: locationData.stateCode,
		county: locationData.county
	};
	var options = opts || {};

	if (options.isCountryChange) {

		// Allow the option to force an empty state/county for when the country changes,
		// but the state/county drop down still has a value from the old country.
		data.stateCode = '';
		data.county = '';

	} else if (options.isStateChange) {

		data.county = '';

	}

	if (hasShipping()) {

		data.shippingId = getShippingId();

	}

	return $.ajax({
		method: 'POST',
		cache: false,
		dataType: 'json',
		url: settings.paymentDetailsUrl,
		data: {
			data: JSON.stringify(data)
		}
	}).done(function(response) {

		if (response.status) {

			$paymentDetailsWrapper.html(response.html);

		} else {

			outdatedMsg.show(response.msg);

		}

	});

};

var getCounties = function($target) {

	var locationData = getSelectedLocation();
	var data = {
		timeStamp: settings.formTimeStamp,
		countryCode: locationData.countryCode,
		stateCode: locationData.stateCode
	};

	return $.ajax({
		type: 'post',
		cache: false,
		dataType: 'json',
		url: settings.paymentCountiesUrl,
		data: {
			data: JSON.stringify(data)
		}
	}).done(function(response) {

		var $html = $(response.html);

		if (response.status) {

			$target.html($html);
			selectmenu.sync($target);
			toggleCounty($target, isCountyPopulated($target));

		} else {

			outdatedMsg.show(response.msg);

		}

	});

};

var doesCountyOptionExist = function($target) {

	return $target.length;

};

var isCountyPopulated = function($target) {

	return !!$target[0].length;

};

var getCountyWrapper = function($target) {

	return $target.closest('.fb-address-county');

};

var toggleCounty = function($target, isVisible) {

	getCountyWrapper($target).toggleClass('fb-address-county-is-visible', isVisible);

};

var hideCounty = function($target) {

	toggleCounty($target, false);

};

var showCounty = function($target) {

	toggleCounty($target, true);

};

var removeCounties = function($target) {

	hideCounty($target);
	$target.empty();
	selectmenu.sync($target);

};

var injectStripeLibrary = function() {

	console.log('Injecting Stripe library!');

	$.ajax({
		url: 'https://js.stripe.com/v2/',
		dataType: 'script',
		cache: true
	});

};

var addEventHandlers = function() {

	dom.fbForm.off('.fb-payment');

	dom.fbForm.on('change.fb-payment', '#fbPaymentShippingAddressOptions input[type="radio"]', function() {

		var selectedOption = getSelectedOptionId(this.id);

		if (selectedOption === 0) {

			$shippingAddressOptionsWrapper.addClass('fb-payment-shipping-address-options-same');

			if (doesCountyOptionExist($billingAddressCounty)) {

				if (isCountyPopulated($billingAddressCounty)) {

					showCounty($billingAddressCounty);

				}

			}

		} else {

			$shippingAddressOptionsWrapper.removeClass('fb-payment-shipping-address-options-same');

			// County only matters for tax purposes and shipping address will
			// now be used for taxes, so we don't need the billing address
			// county any more, if it even existed.
			if (doesCountyOptionExist($billingAddressCounty)) {

				hideCounty($billingAddressCounty);

			}

		}

		// We could have different data in the billing and shipping address
		// fields. So each time we toggle, we need to refresh.
		getShippingMethods();
		getPaymentDetails();

		emitter.emit('fbWidget-payment-shipping-address-option-changed');

	});

	dom.fbForm.on('change.fb-payment', '#payment-billing-address-country', function() {

		console.log('Payment billing address country changed.');

		if (isShippingAndBillingAddressSame()) {

			if (hasShipping()) {

				// Country change may change cost of shipping methods.
				getShippingMethods();

			}

			if (doesCountyOptionExist($billingAddressCounty)) {

				// We no longer have a selected state, so clear and hide counties.
				removeCounties($billingAddressCounty);

			}

			// Country change may change cost of shipping and tax.
			getPaymentDetails({isCountryChange: true});

		}

	});

	dom.fbForm.on('change.fb-payment', '#payment-billing-address-state', function() {

		console.log('Payment billing address state changed.');

		if (isShippingAndBillingAddressSame()) {

			if (doesCountyOptionExist($billingAddressCounty)) {

				getCounties($billingAddressCounty);

			}

			// State change may change cost of tax.
			getPaymentDetails({isStateChange: true});

		}

	});

	dom.fbForm.on('change.fb-payment', '#payment-shipping-address-country', function() {

		console.log('Payment shipping address country changed.');

		// Country change may change cost of shipping methods.
		getShippingMethods();

		if (doesCountyOptionExist($shippingAddressCounty)) {

			// We no longer have a selected state, so clear and hide counties.
			removeCounties($shippingAddressCounty);

		}

		// Country change may change cost of shipping and tax.
		getPaymentDetails({isCountryChange: true});

	});

	dom.fbForm.on('change.fb-payment', '#payment-shipping-address-state', function() {

		console.log('Payment shipping address state changed.');

		if (doesCountyOptionExist($shippingAddressCounty)) {

			getCounties($shippingAddressCounty);

		}

		// State change may change cost of tax.
		getPaymentDetails({isStateChange: true});

	});

	dom.fbForm.on('change.fb-payment', '.fb-address-county select', function() {

		console.log('Payment address county changed.');

		// County change may change cost of tax.
		getPaymentDetails();

	});

	dom.fbForm.on('change.fb-payment', '#fbPaymentShippingMethod input[type="radio"]', function() {

		console.log('Payment shipping method changed.');

		getPaymentDetails();

	});

	dom.fbForm.on('change.fb-payment', '#fbPaymentPaymentMethod input[type="radio"]', function() {

		emitter.emit('fbWidget-payment-method-changed');

	});

	emitter.on('fbWidget-option-field-with-fees-changed', function() {

		console.log('An option field with one or more additional fee options was just changed.');

		if (hasShipping()) {

			// This form is collecting shipping information and the sub total
			// just changed, which may change shipping costs that are based
			// on percentages.
			getShippingMethods();

		}

		getPaymentDetails();

	});

	emitter.on('fbWidget-destroy', function() {

		$shippingAddressWrapper = null;
		$shippingAddressOptionsWrapper = null;
		$shippingMethodWrapper = null;
		$billingAddressCountry = null;
		$billingAddressState = null;
		$billingAddressCounty = null;
		$shippingAddressCountry = null;
		$shippingAddressState = null;
		$shippingAddressCounty = null;
		$paymentDetailsWrapper = null;
		$paymentMethodWrapper = null;
		hasPaymentField = null;

	});

};

var getSelectedMerchant = function() {

	return $paymentMethodWrapper.find('input[type="radio"]:checked');

};

var isMerchantStripe = function() {

	return getSelectedMerchant()[0].hasAttribute('data-stripe');

};

var getEmptyCCData = function() {

	return {
		type: '',
		name: '',
		number: '',
		expMonth: '',
		expYear: '',
		verification: ''
	};

};

module.exports = {

	init: function() {

		hasPaymentField = !!dom.fbForm.find('.fb-payment-type').length;

		if (hasPaymentField) {

			$billingAddressCountry = dom.fbForm.find('#payment-billing-address-country');
			$billingAddressState = dom.fbForm.find('#payment-billing-address-state');
			$billingAddressCounty = dom.fbForm.find('#payment-billing-address-county');
			$shippingAddressWrapper = dom.fbForm.find('#fbPaymentShippingAddress');
			$shippingAddressCountry = $shippingAddressWrapper.find('#payment-shipping-address-country');
			$shippingAddressState = $shippingAddressWrapper.find('#payment-shipping-address-state');
			$shippingAddressCounty = $shippingAddressWrapper.find('#payment-shipping-address-county');
			$shippingAddressOptionsWrapper = $shippingAddressWrapper.find('#fbPaymentShippingAddressOptions');
			$shippingMethodWrapper = dom.fbForm.find('#fbPaymentShippingMethod');
			$paymentDetailsWrapper = dom.fbForm.find('#fbPaymentDetails');
			$paymentMethodWrapper = dom.fbForm.find('#fbPaymentPaymentMethod');

			// Inject stripe library if necessary
			if ($paymentMethodWrapper.find('[data-stripe]').length && !window.Stripe) {

				injectStripeLibrary();

			}

			addEventHandlers();

		}

	},

	isPaymentType: function($col) {

		return $col.hasClass('fb-payment-type');

	},

	processCredit: function(paymentData) {

		return $.Deferred(function(dfd) {

			if (hasPaymentField) {

				if (isMerchantStripe()) {

					// Dealing with Stripe, so we need to send them the credit
					// card info to get a token, which we'll send to the server
					// in place of the credit card info

					window.Stripe.setPublishableKey(dom.fbForm.attr('data-spk'));

					window.Stripe.createToken({
						'name': paymentData.value.cc.name,
						'number': paymentData.value.cc.number,
						'cvc': paymentData.value.cc.verification,
						'exp_month': paymentData.value.cc.expMonth,
						'exp_year': paymentData.value.cc.expYear,
						'address_line1': paymentData.value.billingAddress[0].value,
						'address_line2': paymentData.value.billingAddress[1].value,
						'address_state': paymentData.value.billingAddress[4].value,
						// Always look for zip as last element since the presence
						// of a county makes its position variable
						'address_zip': paymentData.value.billingAddress[paymentData.value.billingAddress.length - 1].value,
						'address_country': paymentData.value.billingAddress[3].value
					}, function(status, response) {

						// Clear out cc data, we don't want/need to send it to server
						paymentData.value.cc = getEmptyCCData();

						if (response.error) {

							paymentData.value.cc.token = '';
							paymentData.value.cc.error = response.error;

						} else {

							paymentData.value.cc.token = response.id;
							paymentData.value.cc.error = {};

						}

						dfd.resolve();

					});

				} else {

					// This is a "regular" credit card merchant, we'll send the
					// credit card info to the server for processing

					dfd.resolve();

				}

			} else {

				// This form does not have a payment field, so there is no
				// processing to do

				dfd.resolve();

			}

		}).promise();

	},

	processPaypal: function(paypalData) {

		console.log('paypalData', paypalData);

		// Post to paypal using html form because an ajax post will be blocked
		// due to cross origin restraints
		var form = '<form method="POST" action="' + paypalData.paymentUrl + '">';

		Object.keys(paypalData.data).forEach(function(key) {

			form += '<input type="hidden" name="' + key + '" value="' + paypalData.data[key] + '">';

		});

		form += '</form';

		$(form).appendTo(dom.body).submit();

	},

	getVal: function($col) {

		var name = 'Payment';
		var $billingAddressWrapper = $col.find('.fb-payment-billing-address');
		var $billingAddressInputs = dom.getFieldInputs($billingAddressWrapper);
		var $shippingAddressInputs;
		var $selectedMerchant = getSelectedMerchant();
		var $merchantForm;
		var vals = {
			billingAddress: []
		};

		// Get billing address data.
		$billingAddressInputs.each(function(i) {

			var $el = $billingAddressInputs.eq(i);

			vals.billingAddress.push({
				name: dom.getNestedFieldTitleText($el),
				value: $el.val()
			});

		});

		if (hasShipping()) {

			// This form is collecting shipping address data.

			vals.shippingAddress = [];

			if (isShippingAndBillingAddressSame()) {

				// Billing and shipping address are the same, just copy.
				$.extend(vals.shippingAddress, vals.billingAddress);

			} else {

				// Get shipping address data.
				$shippingAddressInputs = dom.getFieldInputs($shippingAddressWrapper.find('.fb-address'));

				$shippingAddressInputs.each(function(i) {

					var $el = $shippingAddressInputs.eq(i);

					vals.shippingAddress.push({
						name: dom.getNestedFieldTitleText($el),
						value: $el.val()
					});

				});

			}

			// Get the shipping method id.
			vals.shippingId = getShippingId();

		}

		// Get the merchant id.
		vals.merchantId = $selectedMerchant.attr('data-merchant-id');

		// Get the credit card details.
		$merchantForm = $paymentMethodWrapper.find('#fbPaymentMerchantForm' + vals.merchantId);

		if ($merchantForm.length) {

			// This merchant has a form for credit card data
			vals.cc = getEmptyCCData();

			vals.cc.type = $merchantForm.find('select[id$="cc' + vals.merchantId + '"]').val();
			vals.cc.name = $merchantForm.find('input[id$="name' + vals.merchantId + '"]').val();
			vals.cc.number = $merchantForm.find('input[id$="number' + vals.merchantId + '"]').val();
			vals.cc.expMonth = $merchantForm.find('select[id$="month' + vals.merchantId + '"]').val();
			vals.cc.expYear = $merchantForm.find('select[id$="year' + vals.merchantId + '"]').val();
			vals.cc.verification = $merchantForm.find('input[id$="verification' + vals.merchantId + '"]').val();

		}

		return {
			name: name,
			value: vals
		};

	}

};
