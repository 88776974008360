'use strict';

var dom = require('form-widget-dom');
var Modernizr = (typeof window !== "undefined" ? window.Modernizr : typeof global !== "undefined" ? global.Modernizr : null);

var useNativeDate;
var useNativeTime;
var useCustomSelect;
var useJqueryUIDatepicker;
var path = '/forms/widget/';
var guid;
var timeStamp;

var addHooks = function() {

	var classes = '';

	classes += useNativeDate ? ' fb-use-native-date' : '';
	classes += useNativeTime ? ' fb-use-native-time' : '';
	classes += useCustomSelect ? ' fb-use-custom-select' : '';
	classes += useJqueryUIDatepicker ? ' fb-use-jquery-ui-datepicker' : '';

	dom.fbForm.addClass(classes);

};

var getGuidQuery = function() {

	return '?form_guid=' + guid;

};

module.exports = {

	init: function() {

		useNativeDate = Modernizr.touch && Modernizr.inputtypes.date;
		useNativeTime = Modernizr.touch && Modernizr.inputtypes.time;

		// Use custom JS select boxes if we don't have native time support
		// on a touch device. This is necessary to get a consistent and
		// properly layed out UI.
		useCustomSelect = !useNativeTime;

		// Use custom JS date picker if we don't have native date support
		// on a touch device.
		useJqueryUIDatepicker = !useNativeDate;

		guid = dom.fbForm.attr('data-form-guid');
		timeStamp = dom.fbForm.attr('data-form-timestamp');

		addHooks();

	},

	/*get useNativeDate() {

		return useNativeDate;

	},*/

	get useNativeTime() {

		return useNativeTime;

	},

	get useCustomSelect() {

		return useCustomSelect;

	},

	get useJqueryUIDatepicker() {

		return useJqueryUIDatepicker;

	},

	get path() {

		return path;

	},

	get formGuid() {

		return guid;

	},

	get formTimeStamp() {

		return timeStamp;

	},

	get statesUrl() {

		return path + 'get_states.cfm' + getGuidQuery();

	},

	get submitUrl() {

		return path + 'submit_action.cfm' + getGuidQuery();

	},

	get shippingMethodsUrl() {

		return path + 'includes/payment_shipping_methods.cfm' + getGuidQuery();

	},

	get paymentDetailsUrl() {

		return path + 'includes/payment_details.cfm' + getGuidQuery();

	},

	get paymentCountiesUrl() {

		return path + 'includes/payment_county_options.cfm' + getGuidQuery();

	}

};
